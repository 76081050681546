import { NO_INFO, LIKE_STORAGE_KEY } from '~/assets/strings';
import { Presenter } from '~/framework/Presenter';
import { currency } from '~/global-contexts/utils/number';
import { User } from '~/state/mainAppState';
import { PresentPaginUnitListing, PresentableUnit, CompanyUnit } from '~/types/Unit';

export class CompanyUnitListingPresenter extends Presenter<PresentPaginUnitListing> {
  protected createModel(state: User): PresentPaginUnitListing {
    const item: PresentableUnit[] = this.getItems(state.companyUnitsListing?.items);

    const allItem: PresentableUnit[] = [...(this.getItems(state?.prevCompanyUnitsListingItems) || []), ...item];

    const result: PresentPaginUnitListing = {
      currentPage: state.companyUnitsListing?.currentPage ?? 0,
      items: item,
      allItems: allItem,
      pageSize: state.companyUnitsListing?.pageSize ?? 0,
      totalCount: state.companyUnitsListing?.totalCount ?? 0,
      totalPages: state.companyUnitsListing?.totalPages ?? 0,
    };
    return result;
  }

  private getItems = (data?: CompanyUnit[]) => {
    return (
      data?.map((unit) => {
        return {
          id: unit.id ?? '',
          name: unit.title ?? NO_INFO,
          bedrooms: unit.beds?.toLocaleString() ?? NO_INFO,
          bathrooms: unit.baths?.toLocaleString() ?? NO_INFO,
          area: unit.unitSizeSqft?.toLocaleString() ?? NO_INFO,
          image: unit?.primaryImage?.signedUrl ? '/api' + unit.primaryImage.signedUrl : '',
          price: currency(unit.rentalPrice ?? 0, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          marketRent: unit?.marketRent,
          isLiked: this.getIsLikedUnit(unit.id) ?? false,
          pinned: unit.pinned,
          address: unit.singleLineAddress ?? NO_INFO,
        };
      }) || []
    );
  };

  private getIsLikedUnit(unitId?: string) {
    const likedUnit = localStorage.getItem(LIKE_STORAGE_KEY);
    if (likedUnit) {
      const likedUnitList = JSON.parse(likedUnit);
      return likedUnitList.includes(unitId);
    }
    return false;
  }
}
