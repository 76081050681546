import IconEmpty from '~/assets/icons/empty.svg';
import { EMPTY_DESC } from '~/assets/strings';
import { useStyling } from '~/global-contexts/CompanyStyling';
import { useLocalization } from '~/hooks/useLocalization';

export const NothingHere = () => {
  const { companyStyling } = useStyling();
  const { t } = useLocalization();
  return (
    <div class="flex w-full flex-col items-center justify-center">
      <img
        src={companyStyling?.()?.themeEmptyIcon || IconEmpty}
        class="mt-[80px] h-[200px] w-full max-w-[300px] sm:mt-[100px] sm:h-[250px] sm:max-w-[400px] md:mt-[137px] md:h-[350px] md:w-modal-sm"
      />
      <div class="mt-6 text-center text-sm text-text-level03 sm:mt-[24px] sm:text-base">{t(EMPTY_DESC)}</div>
    </div>
  );
};
